<template>
  <v-app>
    <PartialsHeader />
    <v-main>
      <v-container class="fill-height">
        <v-row class="fill-height" align="center" justify="center">
          <v-col class="d-flex justify-center align-center">
            <LoadersFlamingo :size="loaderSize" />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-fade-transition mode="in-out">
      <v-sheet
        v-if="!mounted"
        color="primary"
        style="position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 999999"
        class="d-flex justify-center align-center"
      >
        <v-responsive
          :aspect-ratio="396.9 / 396.9"
          style="max-height: 250px; max-width: 250px"
          class="text-center"
        >
          <img src="~/assets/icon-white.svg" style="max-height: 100%; max-width: 100%" />
        </v-responsive>
      </v-sheet>
    </v-fade-transition>
  </v-app>
</template>

<script lang="ts">
/**
 * This layout is the "loading" screen for the system while required scripts and functionalities
 * are loaded in the background in the main app component.
 */
import { defineComponent, onMounted, ref } from 'vue'
import PartialsHeader from '~/components/partials/header.vue'
import LoadersFlamingo from '~/components/loaders/flamingo.vue'
import { useDisplay } from 'vuetify'
export default defineComponent({
  name: 'DefaultLayout',
  components: {
    PartialsHeader,
    LoadersFlamingo,
  },
  setup() {
    const display = useDisplay()
    const smAndDown = computed(() => display.smAndDown.value)
    const loaderSize = computed(() => (smAndDown.value ? 150 : 300))
    const mounted = ref(false)
    onMounted(() => {
      mounted.value = true
    })
    return {
      loaderSize,
      mounted,
    }
  },
})
</script>
